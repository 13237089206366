import ContactUs from './contact-us';

class CareerVacanciesContactUs extends ContactUs
{
  bindFormSubmit() {
    super.bindFormSubmit(() => {
      const fullName = this.$form.find('#first_name').val() + ' ' + this.$form.find('#last_name').val();
      this.$form.find('[name="fromName"]').val(fullName);
      return 0;
    });
  };
}

export default CareerVacanciesContactUs;
