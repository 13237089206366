import ContactUs from './contact-us';
import GeneralContactUs from './general-contact-us';
import ModalContactUs from './modal-contact-us';
import CareerVacanciesContactUs from './career-vacancies-contact-us';

$(() => {
    [
        new ContactUs('form-hospital-contact-us'),
        new CareerVacanciesContactUs('form-current-vanancies-contact-us'),
        new GeneralContactUs('form-general-contact-us'),
        new GeneralContactUs('form-visitor-contact-us'),
        new ContactUs('form-modal-cws'),
        new ModalContactUs('form-modal-contact-us'),
    ].forEach(i => i.bindFormSubmit());
});
